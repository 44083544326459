<script>
import Table from "@/components/tables/table.vue";
import axios from "axios";
import CreatePayment from "@/views/pages/payments/modals/CreatePayment.vue";
import UpdatePayment from "@/views/pages/payments/modals/UpdatePayment.vue";
import DeletePayment from "@/views/pages/payments/modals/DeletePayment.vue";
import PaymentExcelUpload from "@/views/pages/accounts/customers/components/paymentExcelUpload.vue";

export default {
  name: "List",
  components: {
    Table,
    CreatePayment,
    UpdatePayment,
    DeletePayment,
    PaymentExcelUpload
  },
  data() {
    return {
      table: {
        name: 'Payments Table',
        url: '/contract/payment/list/',
        headers: [
          {
            field: 'date',
            label: 'Date',
            align: 'center'
          },
          {
            field: 'amount',
            label: 'Amount',
            align: 'center'
          },
          {
            field: 'company_type',
            label: 'Type',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {value: '', label: 'All'},
              {value: 'customer', label: 'Customer'},
              {value: 'counterparty', label: 'Counterparty'},
              {value: 'unknown', label: 'Unknown'},
            ],
          },
          {
            field: 'company',
            label: 'Company',
            align: 'center',
            excel_data: (payment) => {
              return payment.company ? payment.company.name : ''
            }
          },
          {
            field: 'contract',
            label: 'Contract',
            align: 'center',
            excel_data: (payment) => {
              return payment.contract ? payment.contract.name : ''
            }
          },
          {
            field: 'file',
            label: 'File',
            align: 'center',
            searchable: false,
            excel_data: (payment) => {
              return payment.file ? payment.file.toString().split('/')[payment.file.toString().split('/').length - 1] : ''
            }
          },
          {
            field: 'comment',
            label: 'Comment',
            align: 'center'
          },
          {
            field: 'actions',
            label: 'Actions',
            align: 'center'
          },
        ],
        perPage: 25,
        indexed: true,
        searchable: true,
        selectable: true,
      },

      company_type: {
        customer: 'Customer',
        counterparty: 'Counterparty',
        unknown: 'Unknown'
      }
    }
  },
  methods: {
    async downloadFile(path) {
      if (!path) return alert("Invalid File");
      let url = process.env.VUE_APP_ORDER_URL + path;
      try {
        const response = await axios.get(url, {responseType: 'blob'});
        const blob = new Blob([response.data], {type: response.data.type});
        const blobUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = blobUrl;
        anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        alert('File download failed. File may not exist or may be corrupted. Please try again.');
      }
    },

    formatMoney(value) {
      return parseFloat(value || 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
  }
}
</script>

<template>
  <CreatePayment ref="createPayment" @created="this.$refs.table.getData()"/>
  <UpdatePayment ref="updatePayment" @updated="this.$refs.table.getData()"/>
  <DeletePayment ref="deletePayment" @deleted="this.$refs.table.getData()"/>

  <Table v-bind="table" ref="table" class="mb-4">

    <template v-slot:top-right>
      <div class="d-flex gap-3">
        <PaymentExcelUpload/>
        <b-button @click="this.$refs.createPayment.openModal()" variant="secondary"> Create Payment</b-button>
      </div>
    </template>

    <template v-slot:amount="{row: data}">
      $ {{ formatMoney(data.amount) }}
      <i v-if="data.company.type === 'customer'" v-b-tooltip.hover title="Payment received"
         class="mdi mdi-arrow-down-drop-circle text-success fw-bold"></i>
      <i v-else-if="data.company.type === 'counterparty'" v-b-tooltip.hover title="Payment sent"
         class="mdi mdi-arrow-up-drop-circle text-danger fw-bold"></i>
    </template>

    <template v-slot:contract="{row: data}">
      <router-link v-if="data.company && data.company.slug && data.contract && data.contract.slug" :to="{
        name: 'customer_profile_contracts_orders',
        params: {
          slug: data.company.slug,
          contract_slug: data.contract.slug
        }
      }">
        {{ data.contract ? data.contract.name : '' }}
      </router-link>
      <span v-else>
        {{ data.contarct ? data.contract.name || '' : '' }}
      </span>
    </template>
    <template v-slot:company_type="{row:data}">
      {{ company_type[data.company.type] ? company_type[data.company.type] : data.company.type }}
    </template>
    <template v-slot:company="{row: data}">
      <router-link v-if="data.company && data.company.slug" :to="{
        name: 'customer_profile_main',
        params: {
          slug: data.company.slug
        }
      }">
        {{ data.company.name }}
      </router-link>
    </template>
    <template v-slot:file="{row: data}">
      <i v-if="data.file" @click="downloadFile(data.file)"
         class="mdi mdi-file fs-4 text-secondary c_icon c_icon_hoverable"></i>
      <span v-else>-</span>
    </template>


    <template v-slot:actions="{row: data}">
      <b-button-group class="mt-4 mt-md-0" role="group" aria-label="Basic example">
        <b-button @click="this.$refs.updatePayment.openModal(data)" variant="light" class="ps-3 pe-2">
          <i class="ri-pencil-fill align-bottom fs-5"></i>
        </b-button>
        <b-button @click="this.$refs.deletePayment.openModal(data)" variant="light">
          <i class="ri-delete-bin-7-fill align-bottom text-danger fs-5"></i>
        </b-button>
      </b-button-group>

    </template>

  </Table>
</template>

<style scoped>

</style>