<script>
import SelectCompany from "@/components/custom/SelectCompany.vue";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "UpdatePayment",
  components: {
    SelectCompany,
    Multiselect
  },
  emits: ['updated'],
  data() {
    return {
      payment_id: null,
      company: null,
      contract: null,
      amount: null,
      date: null,
      comment: '',

      contract_options: [],

      showModal: false,
      is_submitting_form: false,
    }
  },
  computed: {
    isFormValid() {
      return this.company && this.contract && this.amount && this.date
    }
  },
  methods: {
    async openModal(data) {
      this.payment_id = data.id;

      this.company = {
        value: data.company.id,
        label: data.company.name,
        slug: data.company.slug,
      }

      this.contract_options = await this.getContractsByCustomer();

      this.contract = {
        value: data.contract.id,
        label: data.contract.name,
        slug: data.contract.slug,
      }

      this.amount = parseFloat(data.amount).toFixed(2);
      this.date = data.date;
      this.comment = data.comment;

      this.showModal = true;
    },
    async onCompanySelect(company) {
      this.company = company;
      this.contract = null;
      this.contract_options = await this.getContractsByCustomer();
      if (this.contract_options.length === 1) {
        this.contract = this.contract_options[0];
      }
    },
    async getContractsByCustomer() {
      if (this.company && this.company.slug) {
        try {
          let response = await axios.get(`/contract/${this.company.slug}/by_company/`);
          return (response.data.results || []).map((contract) => {
            return {
              value: contract.id,
              label: contract.name,
              slug: contract.slug,
            }
          });
        } catch {
          return [];
        }
      }
    },
    async submitForm() {
      try {
        let formData = new FormData();

        formData.append('date', this.date);
        formData.append('amount', this.amount);
        formData.append('comment', this.comment);
        formData.append('contract_slug', this.contract.slug);

        this.is_submitting_form = true;
        await axios.put(`/contract/payment/${this.payment_id}/update/`, formData)
        this.is_submitting_form = false;
        this.$emit('updated');
        await Swal.fire({
          icon: 'success',
          title: 'Payment updated successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } catch (error) {
        this.is_submitting_form = false;
        let html_text = ''
        try {
          html_text = Object.entries(error.response.data.extra.fields) ? Object.entries(error.response.data.extra.fields).map(([key, value]) => {
            return `<span class="fw-medium">${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: </span> ${value}`
          }).join('<br>') : ''
        } catch {
          html_text = 'Error while updating payment'
        }
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          html: html_text
        })
      }
    }
  },
}
</script>

<template>
  <b-modal v-model="showModal" title="Create Payment Modal" hide-footer centered>
    <!-- Secondary Alert -->
    <b-alert show dismissible variant="secondary" class="alert-label-icon rounded-label fade show" role="alert">
      <i class="ri-check-double-line label-icon"></i><strong>Info</strong> - If you want to update payment's file, you
      have to delete the payment and create a new one.
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </b-alert>

    <form @submit.prevent="submitForm()" class="d-flex flex-column gap-4" v-if="payment_id">
      <div>
        <label>
          Company <span class="text-danger">*</span>
        </label>
        <SelectCompany :current_company="company ? {
          id: company.value,
          name: company.label,
        } : {}" @onSelect="onCompanySelect"/>
      </div>
      <Transition>
        <div v-if="company">
          <label>Contract <span class="text-danger">*</span></label>
          <Multiselect v-model="contract" :options="contract_options" :object="true"/>
        </div>
      </Transition>
      <template v-if="contract">
        <div>
          <label>Amount <span class="text-danger">*</span></label>
          <b-form-input v-model="amount" placeholder="Enter amount" type="number" min="0" step="any"/>
        </div>
        <div>
          <label>Date <span class="text-danger">*</span></label>
          <b-form-input v-model="date" type="date"/>
        </div>

        <div>
          <label>
            Comment <small class="text-muted ms-1">(optional)</small>
          </label>
          <textarea v-model="comment" class="form-control" placeholder="Enter comment"></textarea>
        </div>

        <div>
          <b-button class="w-100" variant="outline-secondary" :disabled="!isFormValid || is_submitting_form"
                    :type="is_submitting_form ? 'button' : 'submit'">
            {{ is_submitting_form ? 'Updating...' : 'Update Payment' }}
          </b-button>
        </div>
      </template>
    </form>
  </b-modal>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>