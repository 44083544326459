<script>
import SelectCompany from "@/components/custom/SelectCompany.vue";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CreatePayment",
  components: {
    SelectCompany,
    Multiselect
  },
  emits: ['created'],
  data() {
    return {
      company: null,
      contract: null,
      amount: null,
      date: null,
      file: null,
      comment: '',

      contract_options: [],

      showModal: false,
      is_submitting_form: false,
    }
  },
  computed: {
    isFormValid() {
      return this.company && this.contract && this.amount && this.date && this.file;
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    onCompanySelect(company) {
      this.company = company;
    },
    onFileUpload(event) {
      this.file = event.target.files[0];
    },
    async getContractsByCustomer() {
      this.contract = null;
      if (this.company && this.company.slug) {
        try {
          let response = await axios.get(`/contract/${this.company.slug}/by_company/`);
          this.contract_options = (response.data.results || []).map((contract) => {
            return {
              value: contract.id,
              label: contract.name,
              slug: contract.slug,
              start_date: contract.start_date,
              expire_date: contract.expire_date,
            }
          });
          if (this.contract_options.length === 1) {
            this.contract = this.contract_options[0];
          }
        } catch {
          this.contract_options = [];
        }
      }
    },
    async submitForm() {
      try {
        let formData = new FormData();

        formData.append('date', this.date);
        formData.append('amount', this.amount);
        formData.append('file', this.file);
        formData.append('comment', this.comment);
        formData.append('contract_slug', this.contract.slug);

        this.is_submitting_form = true;
        await axios.post('/contract/payment/create/', formData)
        this.is_submitting_form = false;
        this.$emit('created');
        await Swal.fire({
          icon: 'success',
          title: 'Payment created successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } catch (error) {
        this.is_submitting_form = false;
        let html_text = ''
        try {
          html_text = Object.entries(error.response.data.extra.fields) ? Object.entries(error.response.data.extra.fields).map(([key, value]) => {
            return `<span class="fw-medium">${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: </span> ${value}`
          }).join('<br>') : ''
        } catch {
          html_text = 'Error while creating payment'
        }
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          html: html_text
        })
      }
    }
  },
  watch: {
    company: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getContractsByCustomer();
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Create Payment Modal" hide-footer centered>
    <form @submit.prevent="submitForm()" class="d-flex flex-column gap-4">
      <div>
        <label>
          Company <span class="text-danger">*</span>
        </label>
        <SelectCompany v-model="company" @onSelect="onCompanySelect"/>
      </div>
      <Transition>
        <div v-if="company">
          <label>Contract <span class="text-danger">*</span></label>
          <Multiselect v-model="contract" :options="contract_options" :object="true"/>
        </div>
      </Transition>
      <template v-if="contract">
        <div>
          <label>Amount <span class="text-danger">*</span></label>
          <b-form-input v-model="amount" placeholder="Enter amount" type="number" min="0" step="any"/>
        </div>
        <div>
          <label>Date <span class="text-danger">*</span></label>
          <b-form-input v-model="date" type="date"/>
        </div>
        <div>
          <label>File <span class="text-danger">*</span></label>
          <input class="form-control" ref="fileInput" type="file" @change="onFileUpload">
        </div>

        <div>
          <label>
            Comment <small class="text-muted ms-1">(optional)</small>
          </label>
          <textarea v-model="comment" class="form-control" placeholder="Enter comment"></textarea>
        </div>

        <div>
          <b-button class="w-100" variant="secondary" :disabled="!isFormValid || is_submitting_form"
                    :type="is_submitting_form ? 'button' : 'submit'">
            {{ is_submitting_form ? 'Creating...' : 'Create Payment' }}
          </b-button>
        </div>
      </template>
    </form>
  </b-modal>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>